import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const formatCurrency = new Intl.NumberFormat("de-DE", {
  style: "currency",
  currency: "EUR",
});

export function calculateTimezonedDate(date: Date): Date {
  const tzoffset = new Date().getTimezoneOffset() * 60000;
  // Temporary Fix for date discrepancies to work with current backend version
  const result = new Date(date.getTime() - tzoffset);
  return result;
}

export const handleSmoothScrollToSectionById = (id: string) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({
    behavior: "smooth",
  });
};
