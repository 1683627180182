import { MinusCircle, PlusCircle, User2Icon } from "lucide-react";
import { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Boat } from "@/types";

interface Passengers {
  adults: number;
  kids: number;
}

interface PassengerSelectionProps {
  value: {
    adults: number;
    kids: number;
  };
  onValueChange: (value: Passengers) => void;
  selectedBoat?: Boat;
}

export function PassengerSelection({
  value,
  onValueChange,
  selectedBoat,
}: PassengerSelectionProps): JSX.Element {
  const [tempData, setTempData] = useState<Passengers>(value);
  const [open, setOpen] = useState<boolean>(false);

  const seatLimit = selectedBoat?.seats ?? 12;

  const handleIncreaseAdults = (): void => {
    if (tempData.adults + 1 < seatLimit) {
      setTempData({ ...tempData, adults: tempData.adults + 1 });
    }
  };

  const handleDecreaseAdults = (): void => {
    if (tempData.adults - 1 > 0) {
      setTempData({ ...tempData, adults: tempData.adults - 1 });
    }
  };

  const handleIncreaseKids = (): void => {
    if (tempData.kids + 1 < seatLimit) {
      setTempData({ ...tempData, kids: tempData.kids + 1 });
    }
  };

  const handleDecreaseKids = (): void => {
    if (tempData.kids > 0) {
      setTempData({ ...tempData, kids: tempData.kids - 1 });
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn("w-20 justify-start text-left font-normal")}
        >
          <User2Icon className="mr-2 h-4 w-4" />
          {value.adults + value.kids}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end">
        <div className="flex flex-col gap-3">
          <div>
            <span className="text-center text-sm">Adulti</span>
            <div className="flex items-center gap-1">
              <Button
                variant="ghost"
                disabled={tempData.adults < 2}
                onClick={handleDecreaseAdults}
                className="grid place-items-center focus:outline-none"
              >
                <MinusCircle className="size-6" />
              </Button>
              <span className="text-center leading-6 px-2 font-medium">
                {tempData.adults}
              </span>
              <Button
                variant="ghost"
                disabled={
                  tempData.adults > seatLimit - 1 ||
                  tempData.adults + tempData.kids >= seatLimit
                }
                onClick={handleIncreaseAdults}
                className="grid place-items-center focus:outline-none"
              >
                <PlusCircle className="size-6" />
              </Button>
            </div>
          </div>
          <div>
            <span className="text-center text-sm">Ragazzi (0-8)</span>
            <div className="flex items-center gap-1">
              <Button
                variant="ghost"
                disabled={tempData.kids < 1}
                onClick={handleDecreaseKids}
                className="grid place-items-center focus:outline-none"
              >
                <MinusCircle className="size-6" />
              </Button>
              <span className="text-center leading-6 px-2 font-medium">
                {tempData.kids}
              </span>
              <Button
                variant="ghost"
                disabled={
                  tempData.kids > seatLimit - 1 ||
                  tempData.adults + tempData.kids >= seatLimit
                }
                onClick={handleIncreaseKids}
                className="grid place-items-center focus:outline-none"
              >
                <PlusCircle className="size-6" />
              </Button>
            </div>
          </div>
          <Button
            onClick={() => {
              onValueChange(tempData);
              setOpen(false);
            }}
          >
            OK
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}
