import * as SelectPrimitive from "@radix-ui/react-select";
import { useState } from "react";
import { CheckIcon, ChevronDownIcon } from "lucide-react";

interface BookingSearchBarTourSelectProps {
  value: string;
  onValueChange: (id: string) => void;
}

export enum Tours {
  Tremiti = "tremiti",
  Costa = "costa",
  Croazia = "croazia",
  Notturno = "notturno",
}

export const tours: Record<Tours, string> = {
  [Tours.Tremiti]: "Tremiti",
  [Tours.Costa]: "Costa Garganica",
  [Tours.Croazia]: "Croazia - Pelagosa",
  [Tours.Notturno]: "Costa Notturno",
};

function BookingSearchBarTourSelect({
  value,
  onValueChange,
}: BookingSearchBarTourSelectProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  const tourSelectItems = Object.keys(tours).map((item) => {
    return (
      <SelectPrimitive.Item
        className="relative flex items-center w-full select-none px-4 py-2 cursor-pointer hover:bg-gray-100 transition-colors focus:outline-none focus:bg-gray-100"
        value={item}
        key={item}
      >
        <span className="flex h-3.5 w-3.5  mr-2 items-center justify-center">
          <SelectPrimitive.ItemIndicator>
            <CheckIcon className="h-4 w-4" />
          </SelectPrimitive.ItemIndicator>
        </span>

        <SelectPrimitive.ItemText>
          {tours[item as Tours]}
        </SelectPrimitive.ItemText>
      </SelectPrimitive.Item>
    );
  });
  return (
    <SelectPrimitive.Root
      open={open}
      onOpenChange={setOpen}
      onValueChange={onValueChange}
      value={value}
    >
      <SelectPrimitive.Trigger
        className="w-3/5 flex justify-between items-center px-4 py-2 border rounded-md shadow-sm bg-white hover:bg-gray-50 focus:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        aria-label="Seleziona tipo di tour"
      >
        <SelectPrimitive.Value placeholder="Seleziona il tour" />
        <ChevronDownIcon className="w-4 h-4 ml-auto mr-2" aria-hidden="true" />
        <span className="block mt-0.5 text-sm text-neutral-400 font-light "></span>
      </SelectPrimitive.Trigger>
      <SelectPrimitive.Portal>
        <SelectPrimitive.Content
          side="bottom"
          className="relative z-50 mt-28 max-h-96 min-w-[8rem] overflow-hidden rounded-xl border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <SelectPrimitive.Viewport className="p-1">
            <div className="px-4 py-2 text-muted-foreground">
              Seleziona il tuo tour
            </div>
            {tourSelectItems}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.Arrow />
        </SelectPrimitive.Content>
      </SelectPrimitive.Portal>
    </SelectPrimitive.Root>
  );
}

export default BookingSearchBarTourSelect;
