import { Dispatch, SetStateAction, useState } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addDays } from "date-fns";
import {
  SearchIcon,
  CheckIcon,
  ArrowRightIcon,
  PalmtreeIcon,
  User2Icon,
  MinusCircle,
  PlusCircle,
} from "lucide-react";
import { cn } from "../lib/utils";
import type { BookingSearchValues } from "../pages/hero";
import { Button } from "./ui/button";
import { DatePicker } from "@/components/ui/date-picker";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "./ui/drawer";
import { Label } from "./ui/label";
import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { Checkbox } from "./ui/checkbox";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Tours, tours } from "./home-page-tour-select";

interface PassengersPayload {
  adults: number;
  kids: number;
  exclusive: boolean;
}

interface MobileBookingFormPassengersSelectProps {
  value: {
    adults: number;
    kids: number;
    exclusive: boolean;
  };
  onValueChange: (value: PassengersPayload) => void;
}
const MobileBookingFormPassengersSelect = ({
  value,
  onValueChange,
}: MobileBookingFormPassengersSelectProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const handleIncreaseAdults = (): void => {
    if (value.adults + 1 + value.kids <= 12) {
      onValueChange({ ...value, adults: value.adults + 1 });
    }
  };

  const handleDecreaseAdults = (): void => {
    if (value.adults - 1 >= 2) {
      onValueChange({ ...value, adults: value.adults - 1 });
    }
  };

  const handleIncreaseKids = (): void => {
    if (value.kids + 1 + value.adults <= 12) {
      onValueChange({ ...value, kids: value.kids + 1 });
    }
  };

  const handleDecreaseKids = (): void => {
    if (value.kids - 1 >= 0) {
      onValueChange({ ...value, kids: value.kids - 1 });
    }
  };

  const handleExclusiveChecked = (prev: CheckedState): void => {
    onValueChange({ ...value, exclusive: prev === true });
  };

  return (
    <div className="space-y-1">
      <Label>Partecipanti</Label>
      <div>
        <Popover open={open} onOpenChange={setOpen} modal={true}>
          <PopoverTrigger asChild>
            <button className="relative z-10 flex-1 flex text-left items-center space-x-3 focus:outline-none ml-3">
              <User2Icon className="text-neutral-300 h-5 w-5 lg:w-7 lg:h-7" />
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {value.adults + value.kids} Persone
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  Partecipanti
                </span>
              </div>
            </button>
          </PopoverTrigger>
          <PopoverContent align="start" className="mt-6 rounded-xl">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between space-x-5 w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800">Adulti</span>
                  <span className="text-xs text-neutral-500 font-normal">
                    Età 8 o più
                  </span>
                </div>

                <div>
                  <div className="flex items-center gap-1">
                    <Button
                      variant="ghost"
                      disabled={value.adults < 2}
                      onClick={handleDecreaseAdults}
                      className="grid place-items-center focus:outline-none"
                    >
                      <MinusCircle className="size-6" />
                    </Button>
                    <span className="text-center leading-6 px-2 font-medium">
                      {value.adults}
                    </span>
                    <Button
                      variant="ghost"
                      disabled={
                        value.adults > 11 || value.adults + value.kids >= 12
                      }
                      onClick={handleIncreaseAdults}
                      className="grid place-items-center focus:outline-none"
                    >
                      <PlusCircle className="size-6" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between space-x-5 w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800">Bambini</span>
                  <span className="text-xs text-neutral-500  font-normal">
                    Età 2-7
                  </span>
                </div>

                <div>
                  <div className="flex items-center gap-1">
                    <Button
                      variant="ghost"
                      disabled={value.kids < 1}
                      onClick={handleDecreaseKids}
                      className="grid place-items-center focus:outline-none"
                    >
                      <MinusCircle className="size-6" />
                    </Button>
                    <span className="text-center leading-6 px-2 font-medium">
                      {value.kids}
                    </span>
                    <Button
                      variant="ghost"
                      disabled={
                        value.kids > 11 || value.adults + value.kids >= 12
                      }
                      onClick={handleIncreaseKids}
                      className="grid place-items-center focus:outline-none"
                    >
                      <PlusCircle className="size-6" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800 ">
                    Uso Esclusivo
                  </span>
                  <span className="text-xs text-neutral-500  font-normal">
                    Prenotazione dei 12 posti
                  </span>
                </div>

                <Checkbox
                  className="size-6 mr-4"
                  onCheckedChange={handleExclusiveChecked}
                  checked={value.exclusive}
                />
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
interface MobileBookingFormDatePickerProps {
  value?: Date;
  onValueChange: Dispatch<SetStateAction<Date | undefined>>;
}

const MobileBookingFormDatePicker = ({
  value,
  onValueChange,
}: MobileBookingFormDatePickerProps): JSX.Element => {
  return (
    <div className="space-y-1">
      <Label>Giornata</Label>
      <div>
        <DatePicker value={value} setValue={onValueChange} className="w-full" />
      </div>
    </div>
  );
};

interface MobileBookingFormTourSelectProps {
  value: string;
  onValueChange: Dispatch<SetStateAction<string>>;
}

const MobileBookingFormTourSelect = ({
  value,
  onValueChange,
}: MobileBookingFormTourSelectProps) => {
  const Select = SelectPrimitive.Root;
  const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
  >(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Trigger ref={ref} className={className} {...props}>
      <div className="flex z-10 flex-1 relative flex-shrink-0 items-center space-x-2 cursor-pointer focus:outline-none text-left">
        <div className="text-neutral-400">
          <PalmtreeIcon className="size-5" />
        </div>
        <div className="flex-grow">{children}</div>
      </div>
    </SelectPrimitive.Trigger>
  ));
  SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

  const SelectValue = SelectPrimitive.Value;

  const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
  >(({ className, children, position = "popper", ...props }, ref) => (
    <SelectPrimitive.Portal>
      <SelectPrimitive.Content
        ref={ref}
        className={cn(
          "relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
          position === "popper" &&
            "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
          className,
        )}
        position={position}
        {...props}
      >
        <SelectPrimitive.Viewport
          className={cn(
            "p-1",
            position === "popper" &&
              "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
          )}
        >
          {children}
        </SelectPrimitive.Viewport>
      </SelectPrimitive.Content>
    </SelectPrimitive.Portal>
  ));
  SelectContent.displayName = SelectPrimitive.Content.displayName;

  const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
  >(({ className, children, ...props }, ref) => (
    <SelectPrimitive.Item
      ref={ref}
      className={cn(
        "relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
        className,
      )}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <CheckIcon className="h-4 w-4" />
        </SelectPrimitive.ItemIndicator>
      </span>

      <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  ));
  SelectItem.displayName = SelectPrimitive.Item.displayName;

  return (
    <div className="space-y-1">
      <Label>Tour</Label>
      <div>
        <Select value={value} onValueChange={onValueChange}>
          <SelectTrigger
            className={cn(
              "w-full rounded border px-3 py-2",
              value === undefined && "text-muted-foreground",
            )}
          >
            <SelectValue placeholder="Seleziona il tuo Tour" />
          </SelectTrigger>
          <SelectContent>
            {Object.keys(tours).map((item) => (
              <SelectItem className="" key={item} value={item}>
                {tours[item as Tours]}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

const MainSearchFormMobile = (): JSX.Element => {
  const defaultDate = addDays(new Date(), 1);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<BookingSearchValues>({
    defaultValues: {
      date: defaultDate,
      participants: {
        adults: 2,
        kids: 0,
        exclusive: false,
      },
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<BookingSearchValues> = (formData) => {
    setIsSubmitting(true);
    navigate("/checkout", {
      state: {
        tour: formData.tour,
        participants: formData.participants,
        date: formData.date,
      },
    });
  };

  return (
    <Drawer>
      <DrawerTrigger className="lg:hidden" asChild>
        <button className="bg-white relative flex items-center w-full border border-neutral-200 px-4 py-2 pr-11 rounded-full shadow-lg">
          <SearchIcon className="flex-shrink-0 w-5 h-5" />

          <div className="ml-3 flex-1 text-left overflow-hidden">
            <span className="block font-medium text-sm">Inizia da qui </span>
            <span className="block mt-0.5 text-xs font-light text-neutral-500 line-clamp-1">
              Prenota ora la tua escursione
            </span>
          </div>

          <span className="absolute right-2 top-1/2 transform -translate-y-1/2 w-9 h-9 flex items-center justify-center rounded-full border border-neutral-200">
            <svg
              viewBox="0 0 16 16"
              aria-hidden="true"
              role="presentation"
              focusable="false"
              className="block w-4 h-4"
              fill="currentColor"
            >
              <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
            </svg>
          </span>
        </button>
      </DrawerTrigger>
      <DrawerContent>
        <form
          className="mx-auto w-full max-w-sm"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DrawerHeader>
            <DrawerTitle>Prenota il tuo Tour</DrawerTitle>
            <DrawerDescription>
              Inserisci la tua destinazione, la data e i partecipanti per
              trovare le disponibilit&aacute;.
            </DrawerDescription>
          </DrawerHeader>
          <div className="grid gap-2 p-4 pb-0">
            <Controller
              control={control}
              name="tour"
              rules={{ required: true }}
              render={({ field }) => (
                <MobileBookingFormTourSelect
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="date"
              rules={{ required: true }}
              render={({ field }) => (
                <MobileBookingFormDatePicker
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="participants"
              rules={{ required: true }}
              render={({ field }) => (
                <MobileBookingFormPassengersSelect
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
          </div>
          <DrawerFooter>
            <Button disabled={!isValid || isSubmitting} type="submit">
              Visualizza disponibilit&aacute;
              <ArrowRightIcon className="size-4 ml-2" />
            </Button>
          </DrawerFooter>
        </form>
      </DrawerContent>
    </Drawer>
  );
};

export default MainSearchFormMobile;
