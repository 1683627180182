"use client";

import { format } from "date-fns";

import { Button } from "@/components/ui/button";
import { Calendar, DatePeriod } from "@/components/ui/calendar";
import { FormControl } from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { it } from "date-fns/locale";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";

interface DateTimePickerProps {
  value?: Date;
  onValueChange: (value?: Date) => void;
  disabled?: DatePeriod[];
}

export function DateTimePicker({ value, onValueChange }: DateTimePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState<string>(
    value ? format(value, "HH:mm") : "09:00",
  );
  const [date, setDate] = useState<Date | undefined>(value || undefined);

  useEffect(() => {
    if (value) {
      setDate(value);
      setTime(format(value, "HH:mm"));
    }
  }, [value]);

  const handleDateChange = (selectedDate?: Date) => {
    if (!selectedDate) return;

    const [hours, minutes] = time.split(":");
    selectedDate.setHours(parseInt(hours), parseInt(minutes));

    setDate(selectedDate);
    onValueChange?.(selectedDate);
  };

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);

    if (date) {
      const [hours, minutes] = newTime.split(":");
      const newDate = new Date(date.getTime());
      newDate.setHours(parseInt(hours), parseInt(minutes));

      setDate(newDate);
      onValueChange?.(newDate);
    }
  };

  return (
    <div className="flex w-full gap-4">
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant={"outline"}
              className={cn(
                "w-full font-normal",
                !date && "text-muted-foreground",
              )}
            >
              {date ? (
                `${(format(date, "PPP"), { locale: it })}, ${time}`
              ) : (
                <span>Seleziona una data</span>
              )}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            locale={it}
            mode="single"
            selected={date}
            captionLayout="dropdown"
            onSelect={handleDateChange}
            onDayClick={() => setIsOpen(false)}
            fromYear={2000}
            toYear={new Date().getFullYear()}
            disabled={(date) =>
              Number(date) < Date.now() - 1000 * 60 * 60 * 24 ||
              Number(date) > Date.now() + 1000 * 60 * 60 * 24 * 30
            }
          />
        </PopoverContent>
      </Popover>
      <Select value={time} onValueChange={handleTimeChange}>
        <SelectTrigger className="font-normal focus:ring-0 w-[120px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <ScrollArea className="h-[15rem]">
            {Array.from({ length: 14 }).map((_, i) => {
              const hour = (9 + i).toString().padStart(2, "0"); // Start at 8AM, increment by 1 hour
              const minute = "00"; // Fixed to "00" for hour increments
              return (
                <SelectItem key={i} value={`${hour}:${minute}`}>
                  {hour}:{minute}
                </SelectItem>
              );
            })}
          </ScrollArea>
        </SelectContent>
      </Select>
    </div>
  );
}
