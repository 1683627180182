import { buttonVariants } from "./ui/button";
import { Link } from "react-router-dom";

function Footer(): JSX.Element {
  return (
    <>
      <link rel="preload" as="image" href="/images/verga.svg" />
      <footer>
        <div className="container">
          <div className="w-full grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 py-12">
            <div className="flex flex-col items-start gap-4">
              <div>
                <img
                  className="h-[72px]"
                  src="/images/verga.svg"
                  alt="Verganautic"
                  loading="lazy"
                  width={129}
                  height={72}
                />
                <span className="text-xl font-bold">
                  VERGANAUTIC JET di Giovanni Verga
                </span>
              </div>
            </div>
            <div className="flex flex-col items-start gap-4">
              <h3 className="text-lg font-semibold">Dove trovarci</h3>
              <div className="grid gap-2">
                <p className="text-muted-foreground">
                  Ufficio: Via Scalo Marittimo, Pontile B, Rodi Garganico, FG
                  71012
                </p>
              </div>
            </div>
            <div className="flex flex-col items-start gap-4">
              <h3 className="text-lg font-semibold">Contattaci</h3>
              <Link
                to={"https://wa.me/393510531005"}
                className={buttonVariants({
                  variant: "outline",
                })}
                target="_blank"
              >
                Whatsapp
              </Link>
              <Link
                to={"mailto:verganauticjet@gmail.com"}
                className={buttonVariants({
                  variant: "outline",
                })}
                target="_blank"
              >
                Email
              </Link>
            </div>
          </div>
          <div className="lg:flex items-center justify-between py-2">
            <div className="text-muted-foreground">
              Copyright © 2023 VERGNAUTIC JET
            </div>
            <div>
              Realizzato da{" "}
              <Link
                className="font-semibold text-muted-foreground"
                to="https://techvicious.com"
              >
                TECHVICIOUS
              </Link>
              Thinkative
            </div>
            <div className="flex items-center justify-end">
              <div className="flex items-centers gap-2">
                <Link
                  to="/privacypolicy"
                  className="text-muted-foreground hover:underline"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/tos"
                  className="text-muted-foreground hover:underline"
                >
                  Termini di vendita
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
