import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { cn } from "../../lib/utils";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { useState } from "react";
import { Button } from "./button";
import { it } from "date-fns/locale";

interface DatePickerProps {
  value?: Date;
  setValue: (value?: Date) => void;
  className?: string;
}

// HARDCODE
const endOfSeason: Date = new Date("2024-09-30");

export function DatePicker({
  value,
  setValue,
  className,
}: DatePickerProps): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);
  const today = new Date();

  const handleSetValue = (value?: Date): void => {
    setValue(value);
    setOpen(!open);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "justify-start text-left font-normal truncate",
            value === undefined && "text-muted-foreground",
            className,
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {value !== undefined ? (
            format(value, "PPP", { locale: it })
          ) : (
            <span>Scegli una data</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          locale={it}
          disabled={{ before: addDays(today, 1), after: endOfSeason }}
          mode="single"
          selected={value}
          onSelect={handleSetValue}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
