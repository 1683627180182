import { handleSmoothScrollToSectionById } from "@/lib/utils";
import { ChevronDownIcon } from "lucide-react";

interface ScrollToSectionByIdProps {
  id: string;
}

const ScrollToSectionById = ({ id }: ScrollToSectionByIdProps) => {
  return (
    <button
      type="button"
      onClick={() => handleSmoothScrollToSectionById(id)}
      className="outline-none"
      aria-label="Scroll to next section"
    >
      <ChevronDownIcon className="size-14 text-white animate-bounce" />
    </button>
  );
};

export default ScrollToSectionById;
