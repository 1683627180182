import { addDays } from "date-fns";
import { SearchIcon, MapPinIcon } from "lucide-react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import PassengersInput from "../components/guests-input";
import DatePicker from "../components/booking-search/date-picker";
import { cn } from "../lib/utils";
import type { BookingSearchBarTourItem } from "../types/index";
import MainSearchFormMobile from "../components/main-search-form-mobile";
import BookingSearchBarTourSelect from "../components/home-page-tour-select";
import { Button } from "../components/ui/button";
import ScrollToSectionById from "@/components/ui/scroll-to-section-by-id";
import { Helmet } from "react-helmet-async";
import PagePictureCover from "@/components/layout/page-picture-cover";

export interface BookingSearchBarViewProps {
  tours: BookingSearchBarTourItem[];
}

export interface BookingSearchValues {
  tour: string;
  date?: Date;
  participants: {
    adults: number;
    kids: number;
    exclusive: boolean;
  };
}

function MainSearchForm(): JSX.Element {
  const defaultDate = addDays(new Date(), 1);

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<BookingSearchValues>({
    defaultValues: {
      date: defaultDate,
      participants: {
        adults: 2,
        kids: 0,
        exclusive: false,
      },
    },
  });

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<BookingSearchValues> = (formData) => {
    navigate("/checkout", {
      state: {
        tour: formData.tour,
        participants: formData.participants,
        date: formData.date,
      },
    });
  };

  return (
    <>
      <MainSearchFormMobile />
      <form
        className="hidden lg:flex max-w-7xl mx-auto rounded-full border px-4 py-2 bg-white divide-x-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative flex flex-[1.5]">
          <div className="flex z-10 flex-1 relative flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ">
            <div className="text-neutral-300">
              <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
            </div>
            <div className="flex-grow">
              <Controller
                control={control}
                rules={{ required: true }}
                name="tour"
                render={({ field }) => (
                  <BookingSearchBarTourSelect
                    value={field.value}
                    onValueChange={field.onChange}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 z-10 flex items-center focus:outline-none">
          <Controller
            control={control}
            name="date"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <DatePicker
                  value={field.value}
                  setValue={field.onChange}
                  isDateMobile={false}
                />
              );
            }}
          />
        </div>
        <div className="relative flex flex-1">
          <div className="flex-1 z-10 flex items-center focus:outline-none">
            <Controller
              control={control}
              name="participants"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <PassengersInput
                    value={field.value}
                    setValue={field.onChange}
                    isParticipantsMobile={false}
                  />
                );
              }}
            />
          </div>
          <Button
            type="submit"
            variant="secondary"
            disabled={!isValid}
            className={cn(
              "cursor-pointer h-14 md:h-16 w-14 md:w-16 rounded-full bg-purple-600 hover:bg-purple-700 flex items-center justify-center text-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500",
            )}
            aria-label="Cerca"
          >
            <SearchIcon className="h-4 w-4 lg:w-6 lg:h-6" />
          </Button>
        </div>
      </form>
    </>
  );
}

function HomepageHero(): JSX.Element {
  const cdn = import.meta.env.VITE_CLOUDFRONT_DOMAIN ?? "";
  const backgroundImage = "/images/background.avif";

  return (
    <>
      <Helmet>
        {/* Preload the background image */}
        <link rel="preload" as="image" href={`${cdn}/images/background.avif`} />
      </Helmet>
      <PagePictureCover image={backgroundImage}>
        <div className="flex flex-col items-center text-center w-full space-y-3">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl text-white">
            Il Gargano e le Isole Tremiti
          </h1>

          <div className="hidden lg:block scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0 text-white uppercase">
            come le non hai mai viste prima
          </div>

          <span className="hidden lg:block mt-16 uppercase font-medium text-xl text-white max-w-2xl text-center">
            Vivi con noi un&apos;esperienza indimenticabile alla scoperta delle
            Isole Tremiti e del Gargano a bordo dei nostri jet.
          </span>
          <div className="w-full">
            <MainSearchForm />
            <div className="mt-12 text-center">
              <a
                className="uppercase text-xl font-bold tracking-widest text-white underline-offset-4 border-b-2 border-white border-double"
                href="#retrieve-booking"
              >
                Hai già una prenotazione?
              </a>
            </div>
          </div>
          <div className="absolute mx-auto bottom-0">
            <ScrollToSectionById id="nextSection" />
          </div>
        </div>
      </PagePictureCover>
    </>
  );
}

export default HomepageHero;
