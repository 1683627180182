import type { CheckedState } from "@radix-ui/react-checkbox";
import { MinusCircle, PlusCircle, User2Icon } from "lucide-react";
import { useState } from "react";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";

interface Passengers {
  adults: number;
  kids: number;
  exclusive: boolean;
}

interface PassengersInputProps {
  value: {
    adults: number;
    kids: number;
    exclusive: boolean;
  };
  setValue: (value: Passengers) => void;
  isParticipantsMobile: boolean;
}

const PassengersInput = ({
  value,
  setValue,
  isParticipantsMobile,
}: PassengersInputProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const handleIncreaseAdults = (): void => {
    if (value.adults + 1 + value.kids <= 12) {
      setValue({ ...value, adults: value.adults + 1 });
    }
  };

  const handleDecreaseAdults = (): void => {
    if (value.adults - 1 >= 2) {
      setValue({ ...value, adults: value.adults - 1 });
    }
  };

  const handleIncreaseKids = (): void => {
    if (value.kids + 1 + value.adults <= 12) {
      setValue({ ...value, kids: value.kids + 1 });
    }
  };

  const handleDecreaseKids = (): void => {
    if (value.kids - 1 >= 0) {
      setValue({ ...value, kids: value.kids - 1 });
    }
  };

  const handleExclusiveChecked = (prev: CheckedState): void => {
    setValue({ ...value, exclusive: prev === true });
  };

  return (
    <>
      {!isParticipantsMobile ? (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
          <PopoverTrigger asChild>
            <button className="relative z-10 flex-1 flex text-left items-center space-x-3 focus:outline-none ml-3">
              <User2Icon className="text-neutral-300 h-5 w-5 lg:w-7 lg:h-7" />
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {value.adults + value.kids} Persone
                </span>
                <span className="block mt-1 text-sm text-neutral-700 leading-none font-light">
                  Partecipanti
                </span>
              </div>
            </button>
          </PopoverTrigger>
          <PopoverContent align="start" className="mt-6 rounded-xl">
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between space-x-5 w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800">Adulti</span>
                  <span className="text-xs text-neutral-500 font-normal">
                    Età 8 o più
                  </span>
                </div>

                <div>
                  <div className="flex items-center gap-1">
                    <Button
                      variant="ghost"
                      disabled={value.adults < 2}
                      onClick={handleDecreaseAdults}
                      className="grid place-items-center focus:outline-none"
                    >
                      <MinusCircle className="size-6" />
                    </Button>
                    <span className="text-center leading-6 px-2 font-medium">
                      {value.adults}
                    </span>
                    <Button
                      variant="ghost"
                      disabled={
                        value.adults > 11 || value.adults + value.kids >= 12
                      }
                      onClick={handleIncreaseAdults}
                      className="grid place-items-center focus:outline-none"
                    >
                      <PlusCircle className="size-6" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between space-x-5 w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800">Bambini</span>
                  <span className="text-xs text-neutral-500  font-normal">
                    Età 2-7
                  </span>
                </div>

                <div>
                  <div className="flex items-center gap-1">
                    <Button
                      variant="ghost"
                      disabled={value.kids < 1}
                      onClick={handleDecreaseKids}
                      className="grid place-items-center focus:outline-none"
                    >
                      <MinusCircle className="size-6" />
                    </Button>
                    <span className="text-center leading-6 px-2 font-medium">
                      {value.kids}
                    </span>
                    <Button
                      variant="ghost"
                      disabled={
                        value.kids > 11 || value.adults + value.kids >= 12
                      }
                      onClick={handleIncreaseKids}
                      className="grid place-items-center focus:outline-none"
                    >
                      <PlusCircle className="size-6" />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-between w-full">
                <div className="flex flex-col">
                  <span className="font-medium text-neutral-800 ">
                    Uso Esclusivo
                  </span>
                  <span className="text-xs text-neutral-500  font-normal">
                    Prenotazione dei 12 posti
                  </span>
                </div>

                <Checkbox
                  className="size-6 mr-4"
                  onCheckedChange={handleExclusiveChecked}
                  checked={value.exclusive}
                />
              </div>
            </div>
          </PopoverContent>
        </Popover>
      ) : (
        <div className="mt-3 relative">
          <div className="flex items-center justify-between space-x-5 w-full">
            <div className="flex flex-col">
              <span className="font-medium text-neutral-800 ">Adulti</span>
              <span className="text-xs text-neutral-500  font-normal">
                Età 8 o più
              </span>
            </div>

            <div>
              <div className="flex items-center gap-1">
                <Button
                  type="button"
                  variant="ghost"
                  disabled={value.adults < 2}
                  onClick={handleDecreaseAdults}
                  className="grid place-items-center focus:outline-none"
                >
                  <MinusCircle className="size-6" />
                </Button>
                <span className="text-center leading-6 px-2 font-medium">
                  {value.adults}
                </span>
                <Button
                  type="button"
                  variant="ghost"
                  disabled={
                    value.adults > 11 || value.adults + value.kids >= 12
                  }
                  onClick={handleIncreaseAdults}
                  className="grid place-items-center focus:outline-none"
                >
                  <PlusCircle className="size-6" />
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between space-x-5 w-full mt-3">
            <div className="flex flex-col">
              <span className="font-medium text-neutral-800 ">Bambini</span>
              <span className="text-xs text-neutral-500  font-normal">
                Età 2-7
              </span>
            </div>

            <div>
              <div className="flex items-center gap-1">
                <Button
                  type="button"
                  variant="ghost"
                  disabled={value.kids < 1}
                  onClick={handleDecreaseKids}
                  className="grid place-items-center focus:outline-none"
                >
                  <MinusCircle className="size-6" />
                </Button>
                <span className="text-center leading-6 px-2 font-medium">
                  {value.kids}
                </span>
                <Button
                  type="button"
                  variant="ghost"
                  disabled={value.kids > 11 || value.adults + value.kids >= 12}
                  onClick={handleIncreaseKids}
                  className="grid place-items-center focus:outline-none"
                >
                  <PlusCircle className="size-6" />
                </Button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full mt-3">
            <div className="flex flex-col">
              <span className="font-medium text-neutral-800 ">
                Uso Esclusivo
              </span>
              <span className="text-xs text-neutral-500 font-normal">
                Prenotazione dei 12 posti
              </span>
            </div>

            <Checkbox
              className="size-6 mr-4"
              onCheckedChange={handleExclusiveChecked}
              checked={value.exclusive}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PassengersInput;
