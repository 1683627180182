import { Helmet } from "react-helmet-async";
import loadable from "@loadable/component";
import HomepageHero from "./hero";

const AccessBookingForm = loadable(
  () => import("../components/access-booking-form"),
);

const RecoverBookingForm = loadable(
  () => import("../components/recover-booking-form"),
);
const MottoSection = loadable(() => import("../components/home-page-section"));
const Home = (): JSX.Element => {
  const domain = import.meta.env.VITE_DOMAIN;

  return (
    <>
      <Helmet>
        <title>
          VergaNautic Jet | Escursioni in barca alle Isole Tremiti e Gargano
        </title>
        <meta
          name="description"
          content="Prenota escursioni in barca per le Isole Tremiti, l'Isola di Pelagosa in Croazia, e lungo la costa del Gargano con VergaNautic Jet."
        />
        <link rel="canonical" href={`${domain}`} />
        {/* Schema.org for Google */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "VergaNautic Jet | Escursioni in barca alle Isole Tremiti e Gargano",
              "description": "Prenota escursioni in barca per le Isole Tremiti, l'Isola di Pelagosa in Croazia, e lungo la costa del Gargano con VergaNautic Jet.",
              "image": "/image/tours/tremiti/carousel/3.avif",
              "url": "${domain}"
            }
          `}
        </script>
      </Helmet>

      <div className="snap-start">
        <HomepageHero />
      </div>
      <div id="nextSection" className="snap-start md:h-screen">
        <div className="min-h-screen grid place-items-center">
          <MottoSection />
        </div>
      </div>
      <div className="snap-start flex flex-col items-center justify-center bg-gray-100 space-y-6 py-6 px-4">
        <div className="text-center mb-16">
          <h2 className="text-2xl font-semibold">
            Accedi alla tua prenotazione
          </h2>
          <div className="text-lg text-foreground">
            Da qui potrai rivedere la tua prenotazione e effettuare il check-in.
          </div>
        </div>
        <div
          className="grid lg:grid-cols-2 items-center gap-x-3 divide-x-0 lg:divide-x-2"
          id="retrieve-booking"
        >
          <AccessBookingForm />
          <RecoverBookingForm />
        </div>
      </div>
    </>
  );
};

export default Home;
