import { loadStripe } from "@stripe/stripe-js";
import CheckoutRentalForm from "@/components/checkout-rental-form";
import { Button } from "@/components/ui/button";
import loadable from "@loadable/component";
import { AlertTriangleIcon, ArrowLeft, LoaderCircleIcon } from "lucide-react";
import { Suspense, useRef, useState, useCallback } from "react";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

const EmbeddedCheckoutProvider = loadable(() =>
  import("@stripe/react-stripe-js").then((mod) => mod.EmbeddedCheckoutProvider),
);

const EmbeddedCheckoutComponent = loadable(() =>
  import("@stripe/react-stripe-js").then((mod) => mod.EmbeddedCheckout),
);

const ErrorState = () => (
  <div className="py-24 grid place-items-center">
    <div className="flex items-center gap-4">
      <AlertTriangleIcon className="size-16 text-destructive/75" />
      <div>
        <span className="text-lg font-medium">Errore imprevisto</span>
        <p className="text-muted-foreground">Per favore riprovare più tardi.</p>
        <Button
          size="sm"
          variant="outline"
          onClick={() => window.location.reload()}
        >
          Riprova
        </Button>
      </div>
    </div>
  </div>
);

const LoadingState = () => (
  <div className="grid place-items-center w-full py-32">
    <LoaderCircleIcon className="size-24 animate-spin" />
  </div>
);

interface BackButtonProps {
  onClick: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => (
  <Button variant="link" onClick={onClick}>
    <ArrowLeft className="size-3 mr-1.5 text-muted-foreground" />
    Indietro
  </Button>
);

const RentalCheckoutPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);
  const checkoutRef = useRef(null);

  const handleBackClick = useCallback(() => {
    setClientSecret(null);
  }, []);

  const renderContent = () => {
    if (isError) return <ErrorState />;
    if (isSubmitting) return <LoadingState />;

    return clientSecret === null ? (
      <CheckoutRentalForm
        isSubmitting={isSubmitting}
        setClientSecret={setClientSecret}
        setIsError={setIsError}
        setIsSubmitting={setIsSubmitting}
      />
    ) : (
      <div id="checkout" ref={checkoutRef}>
        <div className="space-y-2">
          <BackButton onClick={handleBackClick} />
          <Suspense
            fallback={
              <LoaderCircleIcon className="size-32 animate-spin text-muted-foreground" />
            }
          >
            <EmbeddedCheckoutProvider
              stripe={stripePromise}
              options={{ clientSecret }}
            >
              <EmbeddedCheckoutComponent />
            </EmbeddedCheckoutProvider>
          </Suspense>
        </div>
      </div>
    );
  };

  return (
    <div className="max-w-7xl mx-auto pt-32 min-h-screen">
      {renderContent()}
    </div>
  );
};

export default RentalCheckoutPage;
