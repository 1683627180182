type PagePictureCoverProps = {
  image: string;
  children: React.ReactNode;
};

const PagePictureCover = ({ image, children }: PagePictureCoverProps) => {
  const cdn = import.meta.env.VITE_CLOUDFRONT_DOMAIN ?? "";
  const baseImageUrl = `${cdn}${image}`;

  return (
    <div className="relative block p-0 h-screen w-full overflow-hidden">
      <div className="h-full">
        <picture>
          <source
            media="(min-width: 1440px)"
            srcSet={`${baseImageUrl}?width=1440 2x, ${baseImageUrl}?width=1440 1x`}
          />
          <source
            media="(min-width: 1024px)"
            srcSet={`${baseImageUrl}?width=1440`}
          />
          <source
            media="(min-width: 768px)"
            srcSet={`${baseImageUrl}?width=774`}
          />
          <source
            media="(min-width: 320px)"
            srcSet={`${baseImageUrl}?width=576 /`}
          />
          <img
            className="w-full h-full object-cover object-center "
            loading="eager"
            alt="Isole Tremiti"
            src={`${baseImageUrl}?width=1440`}
          />
        </picture>
      </div>
      <div className="absolute inset-0 z-30 text-center pt-36 grid place-items-center">
        <div className="container">{children}</div>
      </div>
    </div>
  );
};

export default PagePictureCover;
