import axios from "axios";

const baseURL = import.meta.env.VITE_BASE_API_URL as string;

if (baseURL === "") {
  throw new Error("VITE_BASE_API_URL is not defined");
}

const axiosInstance = axios.create({
  baseURL: `${baseURL}/public`,
});

export default axiosInstance;
