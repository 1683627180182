import axiosInstance from "@/lib/api";
import { calculateTimezonedDate, cn, formatCurrency } from "@/lib/utils";
import TermsOfSalePage from "@/pages/tos";
import { Boat, BoatType } from "@/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { RadioGroup, RadioGroupItem } from "@radix-ui/react-radio-group";
import { format } from "date-fns";
import { Check } from "lucide-react";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form";
import { z } from "zod";
import { PassengerSelection } from "./checkout/passenger-selection-popover";
import { Alert, AlertDescription, AlertTitle } from "./ui/alert";
import { Button } from "./ui/button";
import { DatePeriod } from "./ui/calendar";
import { Checkbox } from "./ui/checkbox";
import { DatePicker } from "./ui/date-picker";
import { DateTimePicker } from "./ui/date-time-picker";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerTrigger,
} from "./ui/drawer";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";

const displayBoatTypes: Record<BoatType, string> = {
  yacht: "Yacht",
  small_inflatable: "Gommoncino",
  inflatable: "Gommone",
  boat: "Barca",
  other: "Altro",
};

const formatDateTime = (date: Date) => {
  return format(date, "dd MMM yyyy, HH:mm");
};

const formSchema = z.object({
  from: z.string(),
  to: z.string(),
  participants: z.object({
    adults: z.number().min(1).max(12),
    kids: z.number().min(0).max(12),
  }),
  boat: z.string().min(1),
  organizer: z.object({
    firstName: z.string(),
    lastName: z.string(),
    contactInfo: z.object({
      email: z.string().email(),
      phone: z
        .object({
          internationalPrefix: z.string().optional(),
          number: z.string().optional(),
        })
        .optional(),
    }),
  }),
  type: z.enum(["pre", "full"]),
});

const QuoteInvalidState = () => {
  return (
    <Alert variant="destructive">
      <AlertTitle>Parametri di noleggio invalidi.</AlertTitle>
      <AlertDescription>
        Ricontrolla la data e ora di inizio e fine per ottenere un preventivo
        valido.
      </AlertDescription>
    </Alert>
  );
};

const PricingOverviewLoadingState = () => {
  return (
    <div className="space-y-3 animate-pulse">
      <div className="grid gap-1">
        <div className="flex items-center justify-between">
          <div className="bg-gray-200 rounded h-4 w-32"></div>{" "}
          <div className="bg-gray-300 rounded h-4 w-48"></div>{" "}
        </div>
        <div className="flex items-center justify-between">
          <div className="bg-gray-200 rounded h-4 w-24"></div>{" "}
          <div className="bg-gray-300 rounded h-4 w-40"></div>{" "}
        </div>
        <div className="h-4 bg-gray-200 rounded w-full"></div>{" "}
      </div>
      <div className="flex items-center justify-between">
        <div className="bg-gray-200 rounded h-4 w-24"></div>{" "}
        <div className="bg-gray-300 rounded h-4 w-20"></div>{" "}
      </div>
    </div>
  );
};

interface RentalOverviewProps {
  isQuoteInvalid: boolean;
  isLoadingQuote: boolean;
  isSubmitting: boolean;
  selectedBoat?: Boat;
  preAmount: number;
  fullAmount: number;
}

const RentalOverview = ({
  isQuoteInvalid,
  isLoadingQuote,
  isSubmitting,
  selectedBoat,
  preAmount,
  fullAmount,
}: RentalOverviewProps) => {
  const form = useFormContext();

  const from = form.watch("from");
  const to = form.watch("to");
  const tosAcceptance = form.watch("tosAcceptance");

  return (
    <div className="space-y-3">
      <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
        Riepilogo
      </h1>
      {isLoadingQuote ? (
        <PricingOverviewLoadingState />
      ) : isQuoteInvalid ? (
        <QuoteInvalidState />
      ) : (
        <div className="space-y-3">
          <div className="grid gap-1">
            <div className="flex items-center justify-between">
              <div className="text-muted-foreground">
                Inizio e fine noleggio
              </div>
              <div className="font-medium text-right">
                {" "}
                {formatDateTime(from)} - {formatDateTime(to)}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-muted-foreground">Il tuo mezzo</div>
              <div className="font-medium">{selectedBoat!.displayName}</div>
            </div>
            {selectedBoat!.needsLicenseForRenting && (
              <div className="text-red-600 font-medium">
                Nota: Richiede PATENTE NAUTICA
              </div>
            )}
          </div>
          <div className="flex items-center justify-between">
            <span>Prezzo totale</span>
            <span className="text-right font-medium">
              {formatCurrency.format(fullAmount)}
            </span>
          </div>
        </div>
      )}
      <div className="items-top flex space-x-2 mt-4">
        <Controller
          control={form.control}
          name={"tosAcceptance"}
          render={({ field }) => (
            <Checkbox checked={field.value} onCheckedChange={field.onChange} />
          )}
        />

        <div className="grid gap-1.5 leading-none">
          <span>
            Accetto i{" "}
            <Drawer>
              <DrawerTrigger asChild>
                <span className={cn("text-blue-600 cursor-pointer")}>
                  termini e le condizioni di vendita
                </span>
              </DrawerTrigger>
              <DrawerContent>
                <div className="max-h-[400px] overflow-y-scroll">
                  <TermsOfSalePage />
                </div>
                <DrawerFooter>
                  <DrawerClose>
                    <Button variant="outline">
                      OK
                      <Check className="size-4 text-green-600 ml-2" />
                    </Button>
                  </DrawerClose>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
            .
          </span>
          <p className="text-muted-foreground">
            Ho preso visione e accetto i termini e le condizioni di vendita e di
            rimborso.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Button
          type="submit"
          size="lg"
          disabled={
            !form.formState.isValid ||
            !tosAcceptance ||
            isSubmitting ||
            isQuoteInvalid
          }
          variant="secondary"
          onClick={() => {
            form.setValue("type", "pre");
          }}
        >
          Pagamento acconto ({formatCurrency.format(preAmount)})
        </Button>
        <span className="text-center text-sm">oppure</span>
        <Button
          disabled={
            !form.formState.isValid ||
            !tosAcceptance ||
            isSubmitting ||
            isQuoteInvalid
          }
          type="submit"
          size="lg"
          onClick={() => {
            form.setValue("type", "full");
          }}
        >
          Pagamento totale ({formatCurrency.format(fullAmount)})
        </Button>
        <span className="text-center text-xs">
          Pagamento sicuro mediate VISA e Mastercard.
        </span>
      </div>
    </div>
  );
};

const OrganizerInformation = () => {
  const form = useFormContext();

  return (
    <div className="space-y-3">
      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        3. Per confermare la tua prenotazione
      </h2>
      <div className="space-y-2 mt-4">
        <div className="grid lg:grid-cols-2 gap-2">
          <div>
            <span className="font-medium">Nome</span>
            <Controller
              name="organizer.firstName"
              control={form.control}
              render={({ field }) => {
                return <Input className="mt-1" {...field} />;
              }}
            />
          </div>
          <div>
            <span className="font-medium">Cognome</span>
            <Controller
              name="organizer.lastName"
              control={form.control}
              render={({ field }) => {
                return <Input className="mt-1" {...field} />;
              }}
            />
          </div>
          <div>
            <span className="font-medium">Indirizzo e-mail</span>
            <Controller
              name="organizer.contactInfo.email"
              control={form.control}
              render={({ field }) => {
                return <Input className="mt-1" {...field} />;
              }}
            />
          </div>
          <div>
            <span className="font-medium">Numero di telefono</span>
            <Controller
              name="organizer.contactInfo.phone.number"
              control={form.control}
              render={({ field }) => {
                return <Input className="mt-1" {...field} />;
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface SingleDayCalendarProps {
  selectedBoat?: Boat;
  disabledDays?: DatePeriod[];
}

interface MultiDayCalendarProps {
  selectedBoat?: Boat;
  disabledDays?: DatePeriod[];
}

const MultipleDayCalendar = ({
  selectedBoat,
  disabledDays,
}: MultiDayCalendarProps) => {
  const form = useFormContext();

  return (
    <div className="lg:flex items-center gap-6">
      <div className="space-y-3">
        <Label>Passeggeri</Label>
        <Controller
          name="participants"
          control={form.control}
          render={({ field }) => {
            return (
              <div className="flex">
                <PassengerSelection
                  value={field.value}
                  onValueChange={field.onChange}
                  selectedBoat={selectedBoat}
                />
              </div>
            );
          }}
        />
      </div>
      <div className="space-y-3">
        <Label>Data e ora d&apos;inizio</Label>
        <Controller
          name="from"
          control={form.control}
          render={({ field }) => (
            <DateTimePicker
              value={field.value}
              onValueChange={field.onChange}
              disabled={disabledDays}
            />
          )}
        />
      </div>
      <div className="space-y-3">
        <Label>Data e ora di fine</Label>
        <Controller
          name="to"
          control={form.control}
          render={({ field }) => (
            <DateTimePicker
              value={field.value}
              onValueChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

const SingleDayCalendar = ({ selectedBoat }: SingleDayCalendarProps) => {
  interface TimeSlot {
    from: string;
    to: string;
  }

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [date, setDate] = useState<Date | undefined>();
  const [availableTimes, setAvailableTimes] = useState<
    TimeSlot[] | undefined
  >();
  const [selectedTime, setSelectedTime] = useState<string | undefined>();

  const form = useFormContext();
  const boat = form.watch("boat");

  const handleSelectDate = (value?: Date) => {
    if (!value) {
      setSelectedTime(undefined);
    }
    form.setValue("from", "");
    form.setValue("to", "");
    setDate(value);
  };

  const handleSelectTime = (value: string) => {
    if (value && date) {
      setSelectedTime(value);

      const [fromTime, toTime] = value.split("-");

      const utcDate = calculateTimezonedDate(date);

      const fromDateTime = new Date(utcDate);
      fromDateTime.setHours(
        parseInt(fromTime.split(":")[0]),
        parseInt(fromTime.split(":")[1]),
        0,
      );

      const toDateTime = new Date(utcDate);
      toDateTime.setHours(
        parseInt(toTime.split(":")[0]),
        parseInt(toTime.split(":")[1]),
        0,
      );

      form.setValue("from", fromDateTime.toISOString());
      form.setValue("to", toDateTime.toISOString());
    }
  };

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axiosInstance
        .get("/boats/rentable/available-times", {
          params: {
            date: calculateTimezonedDate(date as Date).toISOString(),
            boat,
          },
        })
        .then((res) => setAvailableTimes(res.data))
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (date && boat) {
      setSelectedTime("");
      setAvailableTimes([]);
      fetchData();
    }
  }, [date, boat]);

  return (
    <div className="grid lg:flex gap-1">
      <div className="flex gap-1">
        <Controller
          name="participants"
          control={form.control}
          render={({ field }) => {
            return (
              <PassengerSelection
                value={field.value}
                onValueChange={field.onChange}
                selectedBoat={selectedBoat}
              />
            );
          }}
        />

        <DatePicker value={date} setValue={handleSelectDate} />
      </div>

      <Select
        value={selectedTime}
        onValueChange={handleSelectTime}
        disabled={!date || isLoading}
      >
        <SelectTrigger className="lg:max-w-[280px]">
          <SelectValue
            placeholder={date ? "Seleziona un orario" : "Seleziona una data"}
          />
        </SelectTrigger>
        <SelectContent>
          {availableTimes?.map((item, index) => (
            <SelectItem key={index} value={`${item.from}-${item.to}`}>
              {item.from} - {item.to}
            </SelectItem>
          ))}
          {availableTimes?.length === 0 && "Nessun orario disponibile."}
        </SelectContent>
      </Select>
    </div>
  );
};

interface PeriodSelectionProps {
  selectedBoat: Boat;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PeriodSelection = ({
  selectedBoat,
  setIsError,
}: PeriodSelectionProps) => {
  const [data, setData] = useState<DatePeriod[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/boats/rentable/unavailable-days")
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, [selectedBoat]);

  return (
    <div className="space-y-3">
      <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
        2. Seleziona il periodo di noleggio e i partecipanti
      </h2>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          {selectedBoat?.type === "yacht" ? (
            <MultipleDayCalendar disabledDays={data} />
          ) : (
            <SingleDayCalendar
              selectedBoat={selectedBoat}
              disabledDays={data}
            />
          )}
        </>
      )}
    </div>
  );
};

interface BoatCardProps {
  data: Boat;
  selected: boolean;
}

const BoatCard = ({ data, selected }: BoatCardProps) => {
  return (
    <li className="max-w-xs w-full">
      <div
        style={
          {
            "--image-url": `url(${data.image?.thumbnail?.src}?width=700)`,
          } as React.CSSProperties
        }
        className={cn(
          "group w-full overflow-hidden relative card lg:h-96 rounded shadow-xl mx-auto flex flex-col justify-end p-4 border border-transparent",
          "bg-[image:var(--image-url)] bg-cover bg-center",
          "transition-all duration-500",
          // Apply the black overlay with higher opacity by default
          "after:content-[''] after:absolute after:inset-0 after:bg-black",
          selected
            ? "after:opacity-20 ring ring-primary ring-offset-1"
            : "after:opacity-50",
          // On hover, reduce the opacity of the black overlay (e.g., to 20%)
          "group-hover:after:opacity-20",
        )}
      >
        <div
          className={cn(
            !selected && "opacity-0 ",
            "text relative z-50 group-hover:opacity-100 transition-opacity duration-500",
          )}
        >
          <span className="text-center text-xs text-gray-100">
            {displayBoatTypes[data.type]}
          </span>
          <h2 className="font-bold text-xl md:text-3xl text-gray-50 relative">
            {data.displayName}
          </h2>
          <p className="font-normal text-base text-gray-50 relative my-4">
            {data.specs.power} cv | {data.seats} posti
          </p>
        </div>
      </div>
    </li>
  );
};

interface BoatSelectionProps {
  setSelectedBoat: React.Dispatch<React.SetStateAction<Boat | undefined>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
}

const BoatSelection = ({ setSelectedBoat, setIsError }: BoatSelectionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Boat[] | undefined>([]);

  const form = useFormContext();

  const handleOnValueChange = (
    value: string,
    onValueChange: (value: string) => void,
  ) => {
    const item = data?.find((item: Boat) => item._id === value);
    form.setValue("from", "");
    form.setValue("to", "");
    setSelectedBoat(item as Boat | undefined);
    onValueChange(value);
  };

  useEffect(() => {
    setIsLoading(true);
    setIsError(false);
    axiosInstance
      .get("/boats/rentable")
      .then((res) => setData(res.data))
      .catch((err) => {
        console.error(err);
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <Controller
      name="boat"
      control={form.control}
      render={({ field }) => (
        <div className="space-y-3">
          <h2 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            1. Seleziona la tua imbarcazione
          </h2>
          <RadioGroup
            className="flex flex-col lg:grid lg:grid-cols-4 gap-3 list-none"
            value={field.value}
            onValueChange={(value: string) =>
              handleOnValueChange(value, field.onChange)
            }
          >
            {data?.map((boat) => (
              <RadioGroupItem key={boat._id} value={boat._id}>
                <BoatCard data={boat} selected={field.value === boat._id} />
              </RadioGroupItem>
            ))}
          </RadioGroup>
        </div>
      )}
    />
  );
};

const LoadingState = () => {
  return (
    <div className="flex items-center gap-2 border-2 p-2">
      <div className="grid place-items-center w-12 h-12 rounded-full border animate-pulse bg-muted"></div>
      <div className="h-4 w-[64px] bg-muted animate-pulse"></div>
    </div>
  );
};

interface CheckoutRentalFormProps {
  setClientSecret: React.Dispatch<React.SetStateAction<string | null>>;
  setIsError: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const CheckoutRentalForm = ({
  setClientSecret,
  setIsError,
  setIsSubmitting,
  isSubmitting,
}: CheckoutRentalFormProps) => {
  const [isLoadingQuote, setIsLoadingQuote] = useState<boolean>(false);
  const [isQuoteInvalid, setIsQuoteInvalid] = useState<boolean>(false);
  const [selectedBoat, setSelectedBoat] = useState<Boat | undefined>();
  const [preAmount, setPreAmount] = useState<number>(0);
  const [fullAmount, setFullAmount] = useState<number>(0);

  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      participants: {
        kids: 0,
        adults: 2,
      },
      type: "pre",
      organizer: {
        firstName: "",
        lastName: "",
        contactInfo: {
          email: "",
          phone: {
            number: "",
          },
        },
      },
    },
  });

  const onSubmit: SubmitHandler<z.infer<typeof formSchema>> = (
    values,
  ): void => {
    setIsSubmitting(true);
    axiosInstance
      .post(
        `/create-rental-checkout-session?type=${values.type}&locale=it`,
        values,
      )
      .then((res) => res.data)
      .then((data) => {
        setClientSecret(data.clientSecret as string);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const boat = methods.watch("boat");
  const from = methods.watch("from");
  const to = methods.watch("to");

  useEffect(() => {
    const fetchData = () => {
      setIsError(false);
      setIsQuoteInvalid(false);
      setIsLoadingQuote(true);
      axiosInstance
        .post("/rentals/quote", {
          from: calculateTimezonedDate(new Date(from)),
          to: calculateTimezonedDate(new Date(to)),
          boat,
        })
        .then((res) => {
          setPreAmount(res.data.pre);
          setFullAmount(res.data.full);
        })
        .catch((err) => {
          console.log(err.response);
          if (err.response?.status === 400) {
            setIsQuoteInvalid(true);
          } else {
            console.error(err);
            setIsError(true);
          }
        })
        .finally(() => setIsLoadingQuote(false));
    };

    if (boat && from && to) {
      fetchData();
    }
  }, [boat, from, to]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="lg:col-span-2 space-y-6 border px-6 py-8">
          <h1 className="scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl">
            Organizza il tuo noleggio
          </h1>
          <BoatSelection
            setSelectedBoat={setSelectedBoat}
            setIsError={setIsError}
          />
          {selectedBoat && (
            <PeriodSelection
              selectedBoat={selectedBoat}
              setIsError={setIsError}
            />
          )}
          {selectedBoat && from && to && <OrganizerInformation />}
          {selectedBoat && from && to && (
            <RentalOverview
              isQuoteInvalid={isQuoteInvalid}
              isLoadingQuote={isLoadingQuote}
              isSubmitting={isSubmitting}
              selectedBoat={selectedBoat}
              preAmount={preAmount}
              fullAmount={fullAmount}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CheckoutRentalForm;
