import MaintenancePage from "./components/layout/maintenance-page";

import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./components/layout/root";
import "./index.css";
import ErrorPage from "./pages/error-page";
import Home from "./pages/home";
import reportWebVitals from "./reportWebVitals";
import { sendToAnalytics } from "./analytics";
import loadable from "@loadable/component";

const maintenanceMode = import.meta.env.VITE_MAINTENANCE;

const AboutPage = loadable(() => import("./pages/about"));
const BoatsPage = loadable(() => import("./pages/boats"));
const CheckoutPage = loadable(() => import("./pages/checkout"));
const ContactPage = loadable(() => import("./pages/contact"));
const CostaGarganicaPage = loadable(() => import("./pages/costa"));
const CroaziaPage = loadable(() => import("./pages/croazia"));
const FaqPage = loadable(() => import("./pages/faq"));
const GalleryPage = loadable(() => import("./pages/gallery"));
const BookingDetailsPage = loadable(() => import("./pages/booking-details"));
const ToursPage = loadable(() => import("./pages/tours"));
const TremitiPage = loadable(() => import("./pages/tremiti"));
const TermsOfSalePage = loadable(() => import("./pages/tos"));
const PrivacyPolicyPage = loadable(() => import("./pages/privacy-policy"));
const ReturnPage = loadable(() => import("./pages/return"));
const NotFoundPage = loadable(() => import("./pages/not-found"));
import { Partytown } from "@builder.io/partytown/react";
import RentalCheckoutPage from "./pages/rental-checkout";

const router = createBrowserRouter([
  {
    path: "/",
    element: maintenanceMode === "on" ? <MaintenancePage /> : <RootLayout />,
    children: [
      {
        path: "",
        index: true,
        element: <Home />,
      },
      {
        path: "checkout",
        element: <CheckoutPage />,
      },
      {
        path: "rental",
        element: <RentalCheckoutPage />,
      },
      {
        path: "gallery",
        element: <GalleryPage />,
      },
      {
        path: "boats",
        element: <BoatsPage />,
      },
      {
        path: "tours",
        element: <ToursPage />,
      },
      {
        path: "tours/tremiti",
        element: <TremitiPage />,
      },
      {
        path: "tours/costa",
        element: <CostaGarganicaPage />,
      },
      {
        path: "tours/croazia",
        element: <CroaziaPage />,
      },
      {
        path: "booking-details",
        element: <BookingDetailsPage />,
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "contact",
        element: <ContactPage />,
      },
      {
        path: "faq",
        element: <FaqPage />,
      },
      {
        path: "tos",
        element: <TermsOfSalePage />,
      },
      {
        path: "privacypolicy",
        element: <PrivacyPolicyPage />,
      },
      {
        path: "return",
        element: <ReturnPage />,
      },
      {
        path: "404",
        element: <NotFoundPage />,
      },
    ],
    errorElement: <ErrorPage />,
  },
]);

reportWebVitals(sendToAnalytics);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
      <Partytown debug={true} forward={["dataLayer.push"]} />
    </HelmetProvider>
  </React.StrictMode>,
);
