function TermsOfSalePage(): JSX.Element {
  return (
    <main className="container mx-auto px-4 md:px-6 py-12 md:py-24">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold mb-4 pt-24">
          Termini di Vendita
        </h1>
        <p className="text-gray-500 mb-8">
          Benvenuti ai nostri Termini di Vendita. Vi preghiamo di leggere
          attentamente questi termini poiché regolano l&apos;acquisto e
          l&apos;uso dei nostri prodotti e servizi. VERGANAUTICGARGANO JET si
          riserva il diritto di aggiornare e modificare questi termini in
          qualsiasi momento senza preavviso.
        </p>
        <div className="space-y-6">
          <div>
            <h2 className="text-xl font-bold mb-2">Pagamenti</h2>
            <ul className="space-y-2 text-gray-500">
              <li>
                In caso di pagamento in acconto, al momento dell&apos;imbarco
                sarà possibile pagare tramite POS e contanti.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-2">Regole sui Rimborsi</h2>
            <ul className="space-y-2 text-gray-500">
              <li>
                <span className="font-medium">Rimborso per Maltempo</span>
              </li>
              <li>
                In caso di condizioni meteo-marine avverse, l&apos;escursione
                viene annullata e, per non perdere la prenotazione, verrà
                assegnato un buono da poter utilizzare durante l&apos;anno
                corrente o per quello seguente. Se impossibilitati o non
                interessati al buono, procederemo al rimborso totale del
                biglietto decurtando il costo delle spese istruttorie pari al
                10%.
              </li>
              <li>
                <span className="font-medium">
                  Rimborso per Numero Minimo di Partecipanti
                </span>
              </li>
              <li>
                In caso di mancato raggiungimento del numero minimo di
                partecipanti, verrà restituito l&apos;intero importo versato.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-bold mb-2">
              Note aggiuntive sui rimborsi
            </h2>
            <ul className="space-y-2 text-gray-500">
              <li>
                <span className="font-medium">Modalità di rimborso</span>
              </li>
              <li>
                I rimborsi verranno effettuati tramite lo stesso metodo di
                pagamento utilizzato per l&apos;acquisto originale, salvo
                diversa indicazione del cliente.
              </li>
              <li>
                <span className="font-medium">
                  Termini di Validità dei Buoni
                </span>
              </li>
              <li>
                I buoni emessi a causa di annullamento per maltempo sono validi
                fino alla fine dell&apos;anno successivo alla data di emissione.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TermsOfSalePage;
