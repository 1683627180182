import { addDays, format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { it } from "date-fns/locale";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState } from "react";

interface DatePickerProps {
  value?: Date;
  setValue: (value?: Date) => void;
  isDateMobile: boolean;
}

export default function DatePicker({
  value,
  setValue,
  isDateMobile,
}: DatePickerProps): JSX.Element {
  const startDate = addDays(new Date(), 1);
  const [open, setOpen] = useState<boolean>(false);
  const endOfSeason = new Date("2024-09-30");

  return (
    <>
      {!isDateMobile ? (
        <Popover open={open} onOpenChange={setOpen} modal={true}>
          <PopoverTrigger asChild>
            <button
              className={"w-full flex gap-2 py-2 px-4 focus:outline-none"}
            >
              <CalendarIcon className="text-neutral-400 size-6" />
              {value !== undefined ? (
                format(value, "PPP", { locale: it })
              ) : (
                <span>Scegli una data</span>
              )}
            </button>
          </PopoverTrigger>
          <PopoverContent className="mt-6 w-auto p-0 text-left rounded-xl">
            <Calendar
              mode="single"
              locale={it}
              disabled={{
                before: startDate,
                after: endOfSeason,
              }}
              selected={value}
              onSelect={setValue}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      ) : (
        <div className="p-5 rounded-2xl shadow-lg">
          <span className="block font-semibold text-xl sm:text-2xl">
            Scegli il giorno
          </span>
          <Calendar
            mode="single"
            locale={it}
            disabled={{
              before: startDate,
              after: endOfSeason,
            }}
            selected={value}
            onSelect={setValue}
            initialFocus
          />
        </div>
      )}
    </>
  );
}
