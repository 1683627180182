import { Outlet, ScrollRestoration /* useLocation */ } from "react-router-dom";
import Footer from "../footer";
import Header from "../header";
import { Toaster } from "../ui/toaster";
/* import { useEffect } from "react";
import ReactGA from "react-ga4"; */
import loadable from "@loadable/component";

const TrackPageView = loadable(() => import("../track-page-view"));

function RootLayout(): JSX.Element {
  return (
    <div className="h-screen overflow-y-scroll md:snap-y snap-proximity">
      <div className="relative min-h-screen">
        <ScrollRestoration />
        <Header />
        <Outlet />
      </div>
      <Footer />
      <Toaster />
      <TrackPageView />
    </div>
  );
}

export default RootLayout;
